import {Map as OLMap, View, Feature} from 'ol';
import Layer from 'ol/layer/Layer.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import Point from 'ol/geom/Point.js';
import Geolocation from 'ol/Geolocation.js';
import {Circle as CircleStyle, Fill, Stroke, Style, Text} from 'ol/style.js';
import {Source, Vector as VectorSource} from 'ol/source.js';
import {Vector as VectorLayer} from 'ol/layer.js';
import {fromLonLat, toLonLat} from 'ol/proj.js';
import {Control} from 'ol/control.js';
import Overlay from 'ol/Overlay.js';
import {Select} from 'ol/interaction.js';
import {click} from 'ol/events/condition.js';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue, increment, update, serverTimestamp } from "firebase/database";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// TODO: externalize these values
const styleUrl = 'mapbox://styles/wahlax/clwl3k4ja00nm01ppfw93g7gs';
mapboxgl.accessToken = "pk.eyJ1Ijoid2FobGF4IiwiYSI6ImNsd2xvcnJiZzFhcGkya2w4ejQ3dmo1dHMifQ.efPQE8cI-GdEOrDfTeIsmg";
const farmTileSetName = 'cropvine-farms-tileset';
const recaptchaKey = "6LdckucpAAAAAEusefreX_Sf3nbk74FCUp77aAUV";
const firebaseConfig = {
  apiKey: "AIzaSyBuen7szMobqczjwTMfM6_Hfm_8vCO_GLM",
  authDomain: "cropvinecom.firebaseapp.com",
  databaseURL: "https://cropvinecom-default-rtdb.firebaseio.com/",
  projectId: "cropvinecom",
  storageBucket: "cropvinecom.appspot.com",
  messagingSenderId: "8601659864",
  appId: "1:8601659864:web:3b1db7a9a42193622deaad",
  measurementId: "G-0FLF58T0BN"
};
// app config constants
const TTL_HRS = 3;
const NEAR_BUFFER_MILES = 10;
const MID_BUFFER_MILES = 20;
const FAR_BUFFER_MILES = 40;
const ONFARM_BUFFER_MILES = 0.5;
const ZOOMED_OUT = 11;
const ZOOMED_IN = 15;
const DEFAULT_CENTER_LOCATION = [-122.67528131451769, 45.61883442074269]; // x,y - OR/WA Bridge
const seasonalFruits = ['blackberries','blueberries','boysenberries','cherries','currants','gooseberries','loganberries','marionberries','peaches','raspberries','strawberries','tayberries'];

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(recaptchaKey),
  isTokenAutoRefreshEnabled: true
});

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const analytics = getAnalytics(app);

let userId = undefined;

signInAnonymously(auth)
  .then(() => {
      // Signed in..
      //console.log('signed in')
    })
    .catch((error) => {
      console.log('error: ' + error.code + ', ' + error.message);
    }
    //, {remember: 'sessionOnly'}
  );


onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    const uid = user.uid;
    userId = uid;

    // ...
  } else {
    // User is signed out
    // ...
  }
});

const popup = new Overlay({
  element: document.getElementById('popup'),
  zIndex: 99
});

function showEnableGeoLocationPopup() {
  popup.setPosition(map.getView().getCenter());
  let element = popup.getElement();
  let popover = bootstrap.Popover.getInstance(element);
  if (popover) {
    popover.dispose();
  }

  let popupContent = '<div id="geo-disabled-popup">Please enable geolocation access to use this feature.</div>';

  popover = new bootstrap.Popover(element, {
    animation: false,
    container: element,
    content: popupContent,
    html: true,
    placement: 'top',
    title: 'Unknown location',
  });
  popover.show();
  //setTimeout(function () { popover.dispose() }, 5000);
  return popover;
};

class MyPositionControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {};

    const button = document.createElement('button');
    button.innerHTML = '🧭';

    const element = document.createElement('div');
    element.className = 'my-position-control ol-unselectable ol-control';
    element.appendChild(button);
    
    super({
      element: element,
      target: options.target,
    });

    button.addEventListener('click', this.centerOnGeolocation.bind(this), false);
  }

  centerOnGeolocation() {
    // hide if shown
    let element = popup.getElement();
    let popover = bootstrap.Popover.getInstance(element);
    if (popover) {
      popover.dispose();
    }

    // find current location and zoom to it
    const coordinates = geolocation.getPosition();

    if(coordinates) {
      map.getView().setCenter(coordinates);
      map.getView().setZoom(ZOOMED_OUT);  
    }
    else {
      showEnableGeoLocationPopup();
    }

    this.createBuffer(nearBufferFeature,NEAR_BUFFER_MILES);
    this.createBuffer(midBufferFeature,MID_BUFFER_MILES);
    this.createBuffer(farBufferFeature,FAR_BUFFER_MILES);
  }

  createBuffer(inBufferFeature, inDistance) {

    if(inBufferFeature.getGeometry() !== undefined) {
      //console.log(inDistance + 'mi buffer already loaded');
      return;
    }

    let turfGeometry = positionFeature.clone().getGeometry();

    turfGeometry.transform('EPSG:3857','EPSG:4326');
    let turfPoint = turf.point(turfGeometry.flatCoordinates);
    let turfBuffer = turf.buffer(turfPoint, inDistance, {units: 'miles'});
    let buffer =  new GeoJSON().readFeature(turfBuffer);
    buffer.getGeometry().transform('EPSG:4326', 'EPSG:3857');
    inBufferFeature.setGeometry(buffer.getGeometry());
    setTimeout(function() { inBufferFeature.setGeometry(undefined)}, 25000);

  }
}

class DataCollectionControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {};

    const button = document.createElement('button');
    button.innerHTML = '😍';

    const element = document.createElement('div');
    element.className = 'data-collection-control ol-unselectable ol-control';
    element.appendChild(button);
    
    super({
      element: element,
      target: options.target,
    });

    button.addEventListener('click', this.collectData.bind(this), false);
  }

  hasWhichFruits(fruitData,fruitTypes) {
    let fruits=[];
    if(fruitData) {
      //console.log(fruitData);
      for(let f of fruitTypes) {
        // csv of fruits
        if(fruitData.indexOf(f) != -1) {
          fruits.push(f);
        }
      }
    }

    return fruits;
  }

  collectData() {
    //console.log('button clicked');

    // draw a buffer around the point with turfjs
    let turfGeometry = positionFeature.clone().getGeometry();
    if(!turfGeometry) {
      //console.log('no position');
      showEnableGeoLocationPopup()
      return;
    }

    map.getView().setCenter(geolocation.getPosition());
    map.getView().setZoom(ZOOMED_IN); 

    turfGeometry.transform('EPSG:3857','EPSG:4326');
    let turfPoint = turf.point(turfGeometry.flatCoordinates);
    let turfBuffer = turf.buffer(turfPoint, ONFARM_BUFFER_MILES, {units: 'miles'});
    let buffer =  new GeoJSON().readFeature(turfBuffer);
    buffer.getGeometry().transform('EPSG:4326', 'EPSG:3857');
    onfarmBufferFeature.setGeometry(buffer.getGeometry());
    setTimeout(function() { onfarmBufferFeature.setGeometry(undefined)}, 5000);

    let rendered = mbMap.queryRenderedFeatures({layers:[farmTileSetName]});

    let popupContent = '<div>';
    if(rendered) {
      //console.log('rendered');
      let pointsFound = 0;
      for(const feature of rendered) {
        if(turf.booleanPointInPolygon(feature, turfBuffer)) {
          const farmId = feature.properties.id;
          let farmName = feature.properties.name;
          let renderedFeature = renderedFeaturesLoaded.get(farmId);
          if(renderedFeature) {
            let possibleFruits = this.hasWhichFruits(feature.properties.fruits,seasonalFruits);
            //let possibleFruits = this.hasWhichFruits('strawberries,cherries',seasonalFruits);
            if(possibleFruits.length > 0) {
              pointsFound++;
              popupContent += '<button id="pick" type="button" class="btn btn-primary container-fluid" data-bs-target="#vote-modal" data-bs-toggle="modal" data-bs-dismiss="modal" onclick="prepForm(\'' + farmId + "\',this.innerHTML,\'" + possibleFruits.join(',') +'\')">' + farmName + '</button>';
            }
          }
          else {
            console.log('not loaded yet');
          }
        }
      }

      popupContent += '</div>'; 

      if(pointsFound > 0) {
        document.getElementById('farm-modal-body').innerHTML = popupContent;

        let farmModal = document.getElementById('farm-modal')
        let myModal = new bootstrap.Modal(farmModal,{}); //.show()

        farmModal.addEventListener('hidden.bs.modal', function (event) {
          let fruits = document.voting.fruits.value;
  
          for(const feature of rendered) {

            if(fruits.length>0) { // TODO - check name here
              let popupContent = '<div>';
            
              for(let f of fruits.split(',')) {
                //   <input type="checkbox" class="btn-check" id="btncheck1" autocomplete="off">
                popupContent += '<input type="checkbox" class="btn-check" id="btn-fruit-'+ f +'" autocomplete="off" onclick="upickUpdate()">';
                // <label class="btn btn-outline-primary" for="btncheck1">Checkbox 1</label>
                popupContent += '<label class="btn btn-secondary container-fluid" for="btn-fruit-' + f + '"><h3>' + f +'</h3></label>';
                
              }
    
              popupContent += '</div>';  
              document.getElementById('vote-modal-body').innerHTML = popupContent;
            }
          }
       
  
        }, { once: true });

        let submitButton = document.getElementById('save-vote');
        submitButton.addEventListener('click', function (event) {
          submitButton.disabled=true;
         
          // Reference to the clicks in Firebase.
          const db = getDatabase();

          const farmId = document.voting.farmId.value;
          let farmName = document.voting.farmName.value;
          let fruitList = document.voting.upickFruits.value.split(',');
          let farm = {
            name: farmName,
            updatedTime: serverTimestamp(),
            //crops: {}
          };
          let renderedFeature = renderedFeaturesLoaded.get(farmId);
          for(let f of fruitList) {
            let isFirstInTTL =  renderedFeature.indexOf(f) == -1; 
            farm['crops/' + f] = {
              updatedTime: serverTimestamp(),
              totalCount: increment(1),
              activeCount: isFirstInTTL ? 1 : increment(1)
            };
          }

          if(farm) { // just in case...
            update(ref(db, 'upick_reports/' + farmId), farm);
          }
          
          //alert(document.voting.farmId.value + ': ' + document.voting.upickFruits.value); 
        },{ once: true });

        myModal.show();
      }
      else {
        //console.log('no points in buffer');

        // move to current location
        map.getView().setCenter(geolocation.getPosition());
        // place popup over that location
        popup.setPosition(map.getView().getCenter());
        let element = popup.getElement();
        let popover = bootstrap.Popover.getInstance(element);
        if (popover) {
          popover.dispose();
        }
  
        let popupContent = '<div id="vote-disabled-popup">You are not close enough to a farm to use this feature.</div>';
  
        popover = new bootstrap.Popover(element, {
          animation: false,
          container: element,
          content: popupContent,
          html: true,
          placement: 'top',
          title: 'No Nearby Farms',
        });
        popover.show();
        //setTimeout(function () { popover.dispose() }, 3000);
      }
    }

  }
}

const mbMap = new mapboxgl.Map({
  style: styleUrl,
  attributionControl: true,
  boxZoom: false,
  center: DEFAULT_CENTER_LOCATION,
  container: 'map',
  doubleClickZoom: false,
  dragPan: false,
  dragRotate: false,
  interactive: false,
  keyboard: false,
  pitchWithRotate: false,
  scrollZoom: false,
  touchZoomRotate: false,
});

const mbLayer = new Layer({
  render: function (frameState) {
    const canvas = mbMap.getCanvas();
    const viewState = frameState.viewState;

    const visible = mbLayer.getVisible();
    canvas.style.display = visible ? 'block' : 'none';
    canvas.style.position = 'absolute';

    const opacity = mbLayer.getOpacity();
    canvas.style.opacity = opacity;

    // adjust view parameters in mapbox
    const rotation = viewState.rotation;
    mbMap.jumpTo({
      center: geolocation.getPosition() ? toLonLat(viewState.center) : DEFAULT_CENTER_LOCATION,
      zoom: viewState.zoom - 1,
      bearing: (-rotation * 180) / Math.PI,
      animate: false,
    });

    // cancel the scheduled update & trigger synchronous redraw
    // see https://github.com/mapbox/mapbox-gl-js/issues/7893#issue-408992184
    // NOTE: THIS MIGHT BREAK IF UPDATING THE MAPBOX VERSION
    if (mbMap._frame) {
      mbMap._frame.cancel();
      mbMap._frame = null;
    }
    mbMap._render();

    return canvas;
  },
  source: new Source({
    attributions: [
    ],
  }),
  zIndex: 0
});

const upickSource = new VectorSource({
  features: [],
});

function createUpickStyle(crops) { 

  let cropList = crops.split(',');
  let cropText = '😋';
  if(cropList.length > 0 && cropList.length<4) {
    cropText = '';
    for(let c of cropList) {
      if(cropText.length>0) {
        cropText +='\n';
      }
      switch(c) {
        case 'cherries':
          cropText += '🍒';
          break;
        case 'strawberries':
          cropText += '🍓';
          break;
        case 'blueberries':
          cropText += '🫐'
          break;
        default:
          cropText += '😋';
          break;
      }
    }
  }
  else {
    cropText += 'x' + cropList.length;
  }
  
  return new Style({
    text: new Text({
      text: cropText,
      scale: cropList.length>1 ? 4 : 6
    })
  });
}

const noUpickSource = new VectorSource({
  features: [],
});

function createNoUpickStyle() { 
  return new Style({
    text: new Text({
      text: '🚜',
      scale: 5
    })
  });
};

const upickLayer = new VectorLayer({
  source: upickSource,
  zIndex: 3
});

const noUpickLayer = new VectorLayer({
  source: noUpickSource,
  zIndex: 2
});

const view = new View({
  center: DEFAULT_CENTER_LOCATION,
  zoom: 8,
  minZoom: 6,
  maxZoom: 20
});

const map = new OLMap({
  controls: [new DataCollectionControl(), new MyPositionControl()], // no defaults
  //interactions: [], // disabled zoom and pan
  target: 'map',
  layers: [ mbLayer,upickLayer, noUpickLayer],
  overlays: [
    popup],
  view: view
});

const geolocation = new Geolocation({
  // enableHighAccuracy must be set to true to have the heading value.
  trackingOptions: {
    enableHighAccuracy: true,
  },
  projection: view.getProjection(),
});

const renderedFeaturesLoaded = new Map();
geolocation.on('change', function () {
  console.log('geolocation change');
});


const upickSelectClick = new Select({
  condition: click,
  style: function(feature) {
    let featureType = renderedFeaturesLoaded.get(feature.get('farmId'));
    let endTypeIndex = featureType.indexOf(':');
    if(endTypeIndex == -1) {
      endTypeIndex = featureType.length;
    }
    switch(featureType.substr(0,endTypeIndex)) {
      case 'upick':
        return createUpickStyle(featureType.substr(endTypeIndex+1));
      case 'unknown':
        return createNoUpickStyle();
      default:
        return undefined;
    }
  }
});
map.addInteraction(upickSelectClick);


upickSelectClick.on('select', function(e) {
  //console.log('feature selected');

  let feature = e.target.getFeatures().item(0);

  if(!feature) {
    //console.log('no feature for interaction');
    return;
  }

  popup.setPosition(feature.getGeometry().flatCoordinates);
  let element = popup.getElement();
  let popover = bootstrap.Popover.getInstance(element);
  if (popover) {
    popover.dispose();
  }

  let cropHtml = '';
  let cropList = feature.get('crops');
  if(cropList) {
    for(let c of cropList.split(',')) {
      cropHtml += '<li>' + c + '</li>';
    }
  }
  else {
    cropHtml = 'No reports at this time.';
  }
  let popupContent = '<div id="upick-available-popup"><ul>' + cropHtml + '</ul></div>';

  popover = new bootstrap.Popover(element, {
    animation: false,
    container: element,
    content: popupContent,
    html: true,
    placement: 'top',
    title: feature.get('farmName')
  });
  popover.show();
  //setTimeout(function () { popover.dispose(); }, 5000);
});

function renderPage(e) {

  let rendered = undefined;
  try {
      rendered = mbMap.queryRenderedFeatures({layers:[farmTileSetName]});
      //console.log('tileset is loaded');
  }
  catch(e2) {
    console.log('tileset not loaded');
  }

  if(rendered && rendered.length>0) {
    //console.log('features are rendered');
    let farmCount=0;
    const db = getDatabase();
    for(const feature of rendered) {
      farmCount++;
      const farmId = feature.properties.id;
      let farmName = feature.properties.name;

      if(renderedFeaturesLoaded.has(farmId)) {
        continue;
      }

      //console.log('found ' + farmName);
      renderedFeaturesLoaded.set(farmId, 'unknown');
      const farmRef = ref(db, 'upick_reports/' + farmId);
      onValue(farmRef, (snapshot) => {
        const data = snapshot.val();
        const currentTime = Date.now();
        const expiredTime = currentTime - (TTL_HRS*60*60*1000);
        if(!data || data.updatedTime < expiredTime) {
          //console.log('No data for ' + feature.geometry.coordinates);
          const noUpickFeature = new Feature({
            geometry: new Point(fromLonLat(feature.geometry.coordinates))
          });
          noUpickFeature.set('farmId',farmId);
          noUpickFeature.set('farmName',farmName);
          noUpickFeature.setStyle(createNoUpickStyle())
         // if exists, remove previous noupick feature
         noUpickSource.getFeaturesAtCoordinate(noUpickFeature.getGeometry().getCoordinates()).forEach( function(f) { noUpickSource.removeFeature(f) });
         // if exists, remove previous upick feature
         upickSource.getFeaturesAtCoordinate(noUpickFeature.getGeometry().getCoordinates()).forEach( function(f) { upickSource.removeFeature(f) });
          noUpickSource.addFeature(noUpickFeature);
          renderedFeaturesLoaded.set(farmId,'unknown');
          return;
        }

        const upickFeature = new Feature({
          geometry: new Point(fromLonLat(feature.geometry.coordinates))
        });
        upickFeature.set('farmId',farmId);
        upickFeature.set('farmName',farmName);

        let cropNames = '';
        if(data.crops) {
          for(let c in data.crops) {
            if(data.crops[c].updatedTime>=expiredTime) {
              if(cropNames.length>0) {
                cropNames += ',';
              }
              cropNames += c;
            }
          }
        }
        upickFeature.set('crops',cropNames);

        upickFeature.setStyle(createUpickStyle(cropNames));
        renderedFeaturesLoaded.set(farmId, 'upick:'+cropNames);
        // if exists, remove previous noupick feature
        noUpickSource.getFeaturesAtCoordinate(upickFeature.getGeometry().getCoordinates()).forEach( function(f) { noUpickSource.removeFeature(f); });
        // if exists, remove previous upick feature
        upickSource.getFeaturesAtCoordinate(upickFeature.getGeometry().getCoordinates()).forEach( function(f) { upickSource.removeFeature(f); });
        upickSource.addFeature(upickFeature);

      });
      //console.log('watching ' + farmName);
    }
  }
}

map.on('moveend', function(e) {
  //console.log('moveend change');
  renderPage(e);
});

geolocation.on('error', function (error) {
  console.log('geolocation error'); 

  let popover = showEnableGeoLocationPopup(); 
  setTimeout(function() { 
    window.location.reload(); // always works, not ideal 
  }, 5000);
});


const accuracyFeature = new Feature();
geolocation.on('change:accuracyGeometry', function () {
  //console.log('accuracyGeometry');
  accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
});

const positionFeature = new Feature();
positionFeature.setStyle(
  new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: '#3399CC',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
    }),
  }),
);

const onfarmBufferFeature = new Feature();
onfarmBufferFeature.setStyle(
  new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
      }),
      stroke: new Stroke({
        color: 'rgba(255, 255, 0, 0.2)',
        width: 5,
      })
  }),
);

const nearBufferFeature = new Feature();
nearBufferFeature.setStyle(
  new Style({
      text: new Text({
        text: new String(NEAR_BUFFER_MILES) + ' mi',
        scale: 4,
        placement: 'line',
        stroke:  new Stroke({
          color: 'white'
        }),
        fill: new Fill({
          color: '#1b6b0d',
        }),
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
      }),
      stroke: new Stroke({
        color: 'rgba(255, 255, 0, 0.2)',
        width: 5,
      })
  }),
);

const midBufferFeature = new Feature();
midBufferFeature.setStyle(
  new Style({
    text: new Text({
      text: new String(MID_BUFFER_MILES) + ' mi',
      scale: 4,
      placement: 'line',
      stroke:  new Stroke({
        color: 'white'
      }),
      fill: new Fill({
        color: '#1b6b0d',
      }),
    }),
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
      }),
      stroke: new Stroke({
        color: 'rgba(255, 255, 0, 0.2)',
        width: 5,
      })
  }),
);

const farBufferFeature = new Feature();
farBufferFeature.setStyle(
  new Style({
      text: new Text({
        text: new String(FAR_BUFFER_MILES) + ' mi',
        scale: 4,
        placement: 'line',
        stroke:  new Stroke({
          color: 'white'
        }),
        fill: new Fill({
          color: '#1b6b0d',
        }),
        align: 'center'
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
      }),
      stroke: new Stroke({
        color: 'rgba(255, 255, 0, 0.2)',
        width: 5,
      })
  }),
);

let geoLocationLoaded = false;
geolocation.on('change:position', function () {
  //console.log('change:position');

  // find current location and zoom to it
  const coordinates = geolocation.getPosition();
  positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
  if(!geoLocationLoaded) {
    map.getView().setCenter(coordinates);
    map.getView().setZoom(ZOOMED_OUT);  
    geoLocationLoaded = true;
  }

  //console.log('USER: ' + userId);
});

map.on('loadstart', function () {
  //console.log('load started');
});

map.on('loadend', function () {
  //console.log('load ended');
});

function showSplashPage() {
  
  let splashModal = document.getElementById('splash-modal')
  let myModal = new bootstrap.Modal(splashModal,{});

  let launchAppButton = document.getElementById('launch-app');
  launchAppButton.addEventListener('click', function (event) {

    launchAppButton.disabled=true;
    new VectorLayer({
      map: map,
      source: new VectorSource({
        loader: function(extent, resolution, projection) {
          geolocation.setTracking(true);
          launchAppButton.disabled=false;
          setTimeout(function() { renderPage(); }, 500);
          setTimeout(function() { renderPage(); }, 2500); // just in case
        },
        features: [
            accuracyFeature, positionFeature, onfarmBufferFeature, 
            nearBufferFeature, midBufferFeature, farBufferFeature
         ],
      }),
      zIndex: 1
    });
  },{ once: true});
  myModal.show();
}

map.once('rendercomplete', function (evt) {
  //console.log('rendercomplete called');
  if(geolocation.getTracking()) {
    return;
  }
  showSplashPage();
});

map.on('click', function (evt) {
  let element = popup.getElement();
  let popover = bootstrap.Popover.getInstance(element);
  if (popover) {
    popover.dispose()
  };
});
